import React from "react";
import adyenLogo from "../assets/DIGITAL-Adyen-green-RGB.png";

export const Layout = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => (
  <div
    className="flex-column justify-content-center align-items-center"
    style={{ width: "100vw" }}
  >
    <div
      className="w-100 text-light d-flex flex-column justify-content-center align-items-center"
      style={{
        backgroundColor: "#00112c",
        paddingTop: "30px",
        paddingBottom: "30px",
      }}
    >
      <div style={{ maxWidth: "80%", textAlign: "center" }}>
        <img
          className="mb-4"
          src={adyenLogo}
          style={{ maxWidth: 150 }}
          alt="Adyen Logo"
        />
        <h2>{title}</h2>
        <h3 style={{ color: "#08bf51" }}></h3>
      </div>
    </div>
    <div className="mt-2 register-div w-100 d-flex flex-column justify-content-center align-items-center">
      {children}
    </div>
  </div>
);
