import React, { useState, useEffect } from "react";
import { useAxios } from "../hooks/axios";
import {
  Button,
  Table,
  Modal,
  TextInput,
  Group,
  Textarea,
  Box,
  createStyles,
  ScrollArea,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

interface Location {
  id: string;
  name: string;
  description?: string;
  updatedAt: string;
}

const useStyles = createStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '830px',
    margin: '0 auto',
    padding: theme.spacing.md,
  },
  tableContainer: {
    overflowX: 'auto',
  },
  responsiveTable: {
    minWidth: '100%',
    [theme.fn.smallerThan('sm')]: {
      '& th, & td': {
        padding: '8px 4px',
      },
    },
  },
  responsiveButton: {
    [theme.fn.smallerThan('xs')]: {
      padding: '4px 8px',
      fontSize: theme.fontSizes.xs,
    },
  },
  buttonGroup: {
    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      '& > *': {
        width: '100%',
        marginBottom: theme.spacing.xs,
      },
    },
  },
}));

const LocationList: React.FC = () => {
  const { classes } = useStyles();
  const [locations, setLocations] = useState<Location[]>([]);
  const { request } = useAxios();
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [form, setForm] = useState<{
    id?: string;
    name: string;
    description?: string;
  }>({
    name: "",
    description: "",
  });

  const fetchLocations = async () => {
    try {
      const response = await request({
        url: "/location/getAllLocations",
        method: "GET",
      });
      setLocations(response);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const openCreateModal = () => {
    setSelectedLocation(null);
    setForm({
      name: "",
      description: "",
    });
    setModalOpened(true);
  };

  const openEditModal = (location: Location) => {
    setSelectedLocation(location);
    setForm({
      id: location.id,
      name: location.name,
      description: location.description || "",
    });
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
    setSelectedLocation(null);
    setForm({
      name: "",
      description: "",
    });
  };

  const handleSave = async () => {
    try {
      const pin = localStorage.getItem("setup-pin");
      if (selectedLocation) {
        // Update location
        await request({
          url: "/location/updateLocation",
          method: "POST",
          data: {
            pin,
            id: form.id,
            name: form.name,
            description: form.description,
          },
        });
        notifications.show({
          title: "Success",
          message: "Location updated successfully",
          color: "green",
        });
      } else {
        // Create location
        await request({
          url: "/location/createLocation",
          method: "POST",
          data: {
            pin,
            name: form.name,
            description: form.description,
          },
        });
        notifications.show({
          title: "Success",
          message: "Location created successfully",
          color: "green",
        });
      }
      handleCloseModal();
      fetchLocations();
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.response?.data?.message || "Operation failed",
        color: "red",
      });
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this location?")) {
      return;
    }
    try {
      const pin = localStorage.getItem("setup-pin");
      await request({
        url: "/location/deleteLocation",
        method: "POST",
        data: {
          pin,
          id,
        },
      });
      notifications.show({
        title: "Success",
        message: "Location deleted successfully",
        color: "green",
      });
      fetchLocations();
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.response?.data?.message || "Deletion failed",
        color: "red",
      });
    }
  };

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
  };

  return (
    <Box className={classes.container}>
      <Button onClick={openCreateModal} mb="md" className={classes.responsiveButton}>
        Add Location
      </Button>

      <ScrollArea className={classes.tableContainer}>
        <Table className={classes.responsiveTable} highlightOnHover>
          <thead>
            <tr>
              <th>#</th>
              <th>Location Name</th>
              <th>Description</th>
              <th>Actions</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((location: Location, index: number) => (
              <tr key={location.id}>
                <td>{index + 1}</td>
                <td>{location.name}</td>
                <td>{location.description}</td>
                <td>
                  <Group spacing="xs" className={classes.buttonGroup}>
                    <Button
                      size="xs"
                      onClick={() => openEditModal(location)}
                      color="blue"
                      className={classes.responsiveButton}
                    >
                      Edit
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleDelete(location.id)}
                      color="red"
                      className={classes.responsiveButton}
                    >
                      Delete
                    </Button>
                  </Group>
                </td>
                <td>{formatDate(location.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>

      <Modal
        opened={modalOpened}
        onClose={handleCloseModal}
        title={selectedLocation ? "Edit Location" : "Create Location"}
      >
        <TextInput
          label="Location Name"
          placeholder="Enter location name"
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          required
          mb="sm"
        />
        <Textarea
          label="Description"
          placeholder="Enter description"
          value={form.description}
          onChange={(e) => setForm({ ...form, description: e.target.value })}
          mb="sm"
        />
        <Group position="right" mt="md">
          <Button onClick={handleSave} className={classes.responsiveButton}>
            {selectedLocation ? "Update" : "Create"}
          </Button>
          <Button variant="outline" onClick={handleCloseModal} className={classes.responsiveButton}>
            Cancel
          </Button>
        </Group>
      </Modal>
    </Box>
  );
};

export default LocationList;
