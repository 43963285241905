import React, { useState, useEffect } from "react";
import { useAxios } from "../hooks/axios";
import {
  Button,
  Table,
  Modal,
  TextInput,
  Checkbox,
  Group,
  Select,
  Box,
  createStyles,
  ScrollArea,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

interface Terminal {
  id: string;
  name: string;
  isTestTerminal: boolean;
  onboardTerminal: boolean;
  apiKey: string;
  updatedAt: string;
  locationId?: string;
}

interface Location {
  id: string;
  name: string;
}

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "830px",
    margin: "0 auto",
    padding: theme.spacing.md,
  },
  tableContainer: {
    overflowX: "auto",
  },
  responsiveTable: {
    minWidth: "100%",
    [theme.fn.smallerThan("sm")]: {
      "& th, & td": {
        padding: "8px 4px",
      },
    },
  },
  responsiveButton: {
    [theme.fn.smallerThan("xs")]: {
      padding: "4px 8px",
      fontSize: theme.fontSizes.xs,
    },
  },
  buttonGroup: {
    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
      alignItems: "stretch",
      "& > *": {
        width: "100%",
        marginBottom: theme.spacing.xs,
      },
    },
  },
}));

const TerminalList: React.FC = () => {
  const { classes } = useStyles();
  const [terminals, setTerminals] = useState<Terminal[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const { request } = useAxios();
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState<Terminal | null>(
    null
  );
  const [form, setForm] = useState<{
    id: string;
    name: string;
    apiKey: string;
    isTestTerminal: boolean;
    locationId?: string;
  }>({
    id: "",
    name: "",
    apiKey: "",
    isTestTerminal: false,
    locationId: "",
  });

  // Fetch terminals and locations data from backend API
  const fetchTerminals = async () => {
    try {
      const response = await request({
        url: "/terminal/setupPageGetTerminalList",
        method: "POST",
        data: {
          pin: localStorage.getItem("setup-pin"),
        },
      });
      setTerminals(response);
    } catch (error) {
      console.error("Error fetching terminals:", error);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await request({
        url: "/location/getAllLocations",
        method: "GET",
      });
      setLocations(response);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  useEffect(() => {
    fetchTerminals();
    fetchLocations();
  }, []);

  const openCreateModal = () => {
    setSelectedTerminal(null);
    setForm({
      id: "",
      name: "",
      apiKey: "",
      isTestTerminal: false,
      locationId: "",
    });
    setModalOpened(true);
  };

  const openEditModal = (terminal: Terminal) => {
    setSelectedTerminal(terminal);
    setForm({
      id: terminal.id,
      name: terminal.name,
      apiKey: terminal.apiKey,
      isTestTerminal: terminal.isTestTerminal,
      locationId: terminal.locationId || "",
    });
    setModalOpened(true);
  };

  const handleCloseModal = () => {
    setModalOpened(false);
    setSelectedTerminal(null);
    setForm({
      id: "",
      name: "",
      apiKey: "",
      isTestTerminal: false,
      locationId: "",
    });
  };

  const handleSave = async () => {
    try {
      const pin = localStorage.getItem("setup-pin");
      if (selectedTerminal) {
        // Update terminal
        await request({
          url: "/terminal/setupPageUpdateTerminal",
          method: "POST",
          data: {
            pin,
            id: form.id,
            name: form.name,
            isTestTerminal: form.isTestTerminal,
            apiKey: form.apiKey,
            locationId: form.locationId,
          },
        });
        notifications.show({
          title: "Success",
          message: "Terminal updated successfully",
          color: "green",
        });
      } else {
        // Create terminal
        await request({
          url: "/terminal/setupPageCreateTerminal",
          method: "POST",
          data: {
            pin,
            id: form.id,
            name: form.name,
            isTestTerminal: form.isTestTerminal,
            apiKey: form.apiKey,
            locationId: form.locationId,
          },
        });
        notifications.show({
          title: "Success",
          message: "Terminal created successfully",
          color: "green",
        });
      }
      handleCloseModal();
      fetchTerminals();
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.response?.data?.message || "Operation failed",
        color: "red",
      });
    }
  };

  const handleDelete = async (id: string) => {
    if (!window.confirm("Are you sure you want to delete this terminal?")) {
      return;
    }
    try {
      const pin = localStorage.getItem("setup-pin");
      await request({
        url: "/terminal/setupPageDeleteTerminal",
        method: "POST",
        data: {
          pin,
          id,
        },
      });
      notifications.show({
        title: "Success",
        message: "Terminal deleted successfully",
        color: "green",
      });
      fetchTerminals();
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.response?.data?.message || "Deletion failed",
        color: "red",
      });
    }
  };

  const handleToggleShowTerminal = async (
    terminalId: string,
    onboardTerminal: boolean
  ) => {
    if (!window.confirm("Are you sure you want to change this?")) {
      return;
    }
    try {
      const pin = localStorage.getItem("setup-pin");
      await request({
        url: "/terminal/setupPageSetShowHiddenTerminal",
        method: "POST",
        data: {
          pin,
          terminalId,
          onboardTerminal,
        },
      });
      notifications.show({
        title: "Success",
        message: "Terminal visibility updated",
        color: "green",
      });
      fetchTerminals();
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error.response?.data?.message || "Operation failed",
        color: "red",
      });
    }
  };

  const formatDate = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
  };

  return (
    <Box className={classes.container}>
      <Button
        onClick={openCreateModal}
        mb="md"
        className={classes.responsiveButton}
      >
        Add Terminal
      </Button>

      <ScrollArea className={classes.tableContainer}>
        <Table className={classes.responsiveTable} highlightOnHover>
          <thead>
            <tr>
              <th>#</th>
              <th>Terminal ID</th>
              <th>Terminal Name</th>
              <th>Location</th>
              <th>Show/Hide</th>
              <th>Actions</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {terminals.map((terminal: Terminal, index: number) => (
              <tr key={terminal.id}>
                <td>{index + 1}</td>
                <td>{terminal.id}</td>
                <td>{terminal.name}</td>
                <td>
                  {locations.find((loc) => loc.id === terminal.locationId)
                    ?.name || "N/A"}
                </td>
                <td>
                  <Checkbox
                    checked={terminal.onboardTerminal}
                    onChange={() =>
                      handleToggleShowTerminal(
                        terminal.id,
                        !terminal.onboardTerminal
                      )
                    }
                    label={terminal.onboardTerminal ? "Visible" : "Hidden"}
                  />
                </td>
                <td>
                  <Group spacing="xs" className={classes.buttonGroup}>
                    <Button
                      size="xs"
                      onClick={() => openEditModal(terminal)}
                      color="blue"
                      className={classes.responsiveButton}
                    >
                      Edit
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleDelete(terminal.id)}
                      color="red"
                      className={classes.responsiveButton}
                    >
                      Delete
                    </Button>
                  </Group>
                </td>
                <td>{formatDate(terminal.updatedAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>

      <Modal
        opened={modalOpened}
        onClose={handleCloseModal}
        title={selectedTerminal ? "Edit Terminal" : "Create Terminal"}
      >
        <TextInput
          label="Terminal ID"
          placeholder="Enter terminal ID"
          value={form.id}
          onChange={(e) => setForm({ ...form, id: e.target.value })}
          required
          disabled={!!selectedTerminal}
          mb="sm"
        />
        <TextInput
          label="Terminal Name"
          placeholder="Enter terminal name"
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          required
          mb="sm"
        />
        <TextInput
          label="Terminal API Key"
          placeholder="Enter API key"
          value={form.apiKey}
          onChange={(e) => setForm({ ...form, apiKey: e.target.value })}
          mb="sm"
          type="password"
        />
        <Checkbox
          label="Is Test Terminal"
          checked={form.isTestTerminal}
          onChange={(e) =>
            setForm({ ...form, isTestTerminal: e.currentTarget.checked })
          }
          mb="sm"
        />
        <Select
          label="Location"
          placeholder="Select a location"
          data={locations.map((loc) => ({
            value: loc.id,
            label: loc.name,
          }))}
          value={form.locationId}
          onChange={(value) => setForm({ ...form, locationId: value ?? "" })}
          mb="sm"
        />
        <Group position="right" mt="md">
          <Button onClick={handleSave} className={classes.responsiveButton}>
            {selectedTerminal ? "Update" : "Create"}
          </Button>
          <Button
            variant="outline"
            onClick={handleCloseModal}
            className={classes.responsiveButton}
          >
            Cancel
          </Button>
        </Group>
      </Modal>
    </Box>
  );
};

export default TerminalList;
