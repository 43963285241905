import React, { useState } from "react";
import {
  Text,
  Button,
  FileInput,
  Notification,
  Loader,
  Progress,
  Group,
  Box,
} from "@mantine/core";
import { IconUpload, IconX, IconCheck, IconFile } from "@tabler/icons-react";
import { Layout } from "../components/Layout";
import { Dropzone } from "@mantine/dropzone";
import axios from "axios";

export const ImportMembersPage = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");
  const [importResult, setImportResult] = useState<{
    createdCount: number;
    updatedCount: number;
  } | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const isCSVFile = file?.type === "text/csv" || file?.name.endsWith(".csv");
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (selectedFile: File | null) => {
    setFile(selectedFile);
    setUploadStatus("idle");
  };

  const handleUpload = async () => {
    if (file) {
      setUploadStatus("loading");
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post(
          (process.env.REACT_APP_API_HOST || "") + "/user/import",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setUploadProgress(percentCompleted);
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          setUploadStatus("success");
          setFile(null);
          setImportResult({
            createdCount: response.data.createdCount,
            updatedCount: response.data.updatedCount,
          });
        } else {
          setUploadStatus("error");
        }
      } catch (error: any) {
        console.error("Upload error:", error);
        setUploadStatus("error");
        setErrorMessage(
          error.response?.data?.message ||
            "An unexpected error occurred. Please try again."
        );
      }
    } else {
      setUploadStatus("error");
    }
  };

  return (
    <Layout title="Import Member CSV">
      <Box
        style={{
          maxWidth: "80vw",
          width: "830px",
          background: "white",
          padding: "50px",
        }}
      >
        <Text size="xl" weight={700} align="center" mb="xl">
          Import Member CSV
        </Text>

        <Dropzone
          h={100}
          accept={["text/csv"]}
          onDrop={(files) => handleFileChange(files[0])}
          mb="md"
        >
          {file ? (
            <Group position="center" spacing="xs">
              <IconFile size={20} />
              <Text>Selected file: {file.name}</Text>
            </Group>
          ) : (
            <Text align="center">Drag CSV file here or click to select</Text>
          )}
        </Dropzone>
        <Text size="sm" color="dimmed" mt="xs">
          Please upload a CSV file with columns: <strong>email</strong>,{" "}
          <strong>firstName</strong>, <strong>lastName</strong>.
        </Text>

        <Button
          fullWidth
          onClick={handleUpload}
          disabled={!file || uploadStatus === "loading"}
          mt="md"
          aria-label="Upload CSV file"
        >
          {uploadStatus === "loading" ? (
            <Loader color="white" size="sm" />
          ) : (
            "Upload CSV"
          )}
        </Button>

        {uploadStatus === "loading" && (
          <Progress value={uploadProgress} mt="md" />
        )}

        {uploadStatus === "loading" && (
          <Notification loading title="Uploading" mt="md">
            Please wait while we process your file...
          </Notification>
        )}

        {uploadStatus === "success" && importResult && (
          <Notification
            icon={<IconCheck size={18} />}
            color="teal"
            title="Upload Successful"
            mt="md"
            onClose={() => {
              setUploadStatus("idle");
              setImportResult(null);
            }}
          >
            <Text>
              Your CSV file has been successfully uploaded and processed.
            </Text>
            <Text mt="xs">Results:</Text>
            <Text component="ul" mt="xs" ml="md">
              <li>{importResult.createdCount} new records created</li>
              <li>{importResult.updatedCount} existing records updated</li>
            </Text>
          </Notification>
        )}

        {uploadStatus === "error" && (
          <Notification
            icon={<IconX size={18} />}
            color="red"
            title="Upload Failed"
            mt="md"
            onClose={() => setUploadStatus("idle")}
          >
            {errorMessage}
          </Notification>
        )}
      </Box>
    </Layout>
  );
};
