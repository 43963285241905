import React from "react";
import "./App.scss";
import { Router } from "./Router";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";


function App() {
  return (
    <MantineProvider>
      <div className="App">
        <Router />
      </div>
      <Notifications position="bottom-right" />
    </MantineProvider>
  );
}

export default App;
