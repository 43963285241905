import React, { forwardRef, useEffect, useState } from "react";
import {
  Group,
  Text,
  Select,
  Button,
  Card,
  Avatar,
  List,
  Divider,
  Loader,
  Box,
} from "@mantine/core";
import { Layout } from "../components/Layout";
import axios from "axios";
import { notifications } from "@mantine/notifications";

interface User {
  id: string;
  email: string;
  phone?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: string;
  updatedAt?: string;
  creationSource?: string;
}

interface CardData {
  user?: User;
  cardAlias: string;
  recognizedBy?: string;
}

interface TerminalOption {
  id: string;
  name: string;
  locationId?: string;
  Location?: {
    id: string;
    name: string;
    description?: string;
  };
}

export const CardCheckPointPage = () => {
  const [terminalOptions, setTerminalOptions] = useState<TerminalOption[]>([]);
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [cardData, setCardData] = useState<CardData | null>(null);
  const [loading, setLoading] = useState(false);

  const getTerminalOptions = async () => {
    const response = await axios.post(
      (process.env.REACT_APP_API_HOST || "") +
        "/terminal/getOnboardingTerminals",
      {
        pin: "1441",
      }
    );

    return response?.data;
  };

  useEffect(() => {
    getTerminalOptions().then((response) => {
      if (response && Array.isArray(response)) {
        setTerminalOptions(response);
      }

      const urlParams = new URLSearchParams(window.location.search);
      const terminalId = urlParams.get("terminalId") || "";
      if (terminalId) {
        setSelectedTerminal(terminalId);
      }
    });
  }, []);

  const handleTriggerCheck = async () => {
    try {
      if (!selectedTerminal) {
        notifications.show({
          title: "Error",
          message: "Please select a terminal",
          color: "red",
        });
        return;
      }
      setCardData(null);
      setLoading(true);
      const response = await axios.post(
        (process.env.REACT_APP_API_HOST || "") + "/user/cardAcquisitionEvent",
        {
          terminalId: selectedTerminal,
        }
      );
      const data: CardData = response.data;
      setCardData(data);
      setLoading(false);

      let title = "Success";
      if (data?.recognizedBy) {
        title += ` [${data.recognizedBy}]`;
      }

      notifications.show({
        title,
        message: `Check triggered successfully`,
        color: "green",
      });
    } catch (error) {
      setLoading(false);
      notifications.show({
        title: "Error",
        message: "Failed to trigger check",
        color: "red",
      });
    }
  };

  const SelectItem = forwardRef<HTMLDivElement, any>(
    ({ value, label, Location, ...others }: any, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text>{label}</Text>
            {Location && (
              <Text size="xs" color="dimmed">
                Location: {Location.name}
              </Text>
            )}
          </div>
        </Group>
      </div>
    )
  );

  return (
    <Layout title="Card Check Point">
      <div
        style={{
          maxWidth: "80vw",
          width: "550px",
          margin: "0 auto",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <Text weight={500} size="lg" mb="sm">
          Select Terminal
        </Text>
        <Select
          placeholder="Select a terminal"
          data={terminalOptions.map((terminalOption) => ({
            value: terminalOption.id,
            label: terminalOption.name,
            Location: terminalOption.Location,
          }))}
          value={selectedTerminal}
          onChange={(value) => {
            setSelectedTerminal(value || "");
            const urlParams = new URLSearchParams(window.location.search);
            if (value) {
              urlParams.set("terminalId", value);
            } else {
              urlParams.delete("terminalId");
            }
            window.history.replaceState(
              null,
              "",
              `${window.location.pathname}?${urlParams.toString()}`
            );
          }}
          itemComponent={SelectItem}
          searchable
          nothingFound="No terminals found"
        />
        {selectedTerminal && (
          <Text mt="sm" color="dimmed">
            Selected Terminal ID: {selectedTerminal}
            {(() => {
              const terminal = terminalOptions.find(
                (t) => t.id === selectedTerminal
              );
              return terminal?.Location ? (
                <>
                  <br />
                  Location: {terminal.Location.name}
                </>
              ) : null;
            })()}
          </Text>
        )}

        <Button
          onClick={handleTriggerCheck}
          fullWidth
          mt="md"
          color="green"
          disabled={loading}
        >
          {loading ? <Loader color="white" size="sm" /> : "Card Acquisition"}
        </Button>

        {cardData && (
          <Card shadow="sm" padding="lg" mt="md">
            <Group>
              <Avatar size={80} radius={80} color="blue" src={null}>
                {cardData.user
                  ? `${cardData.user.firstName?.[0] || ""}${
                      cardData.user.lastName?.[0] || ""
                    }`.toUpperCase()
                  : "N/A"}
              </Avatar>
              <div>
                <Text weight={700} size="xl">
                  {cardData.user
                    ? `${cardData.user.firstName || ""} ${
                        cardData.user.lastName || ""
                      }`
                    : "No User"}
                </Text>
                <Text size="sm" color="dimmed">
                  Card Alias: {cardData.cardAlias}
                </Text>
              </div>
            </Group>

            <Divider my="sm" />

            {cardData.user ? (
              <List spacing="xs" size="sm" center>
                <List.Item>
                  <Text>Email: {cardData.user.email || "N/A"}</Text>
                </List.Item>
              </List>
            ) : (
              <Text>No user information associated with this card.</Text>
            )}
          </Card>
        )}
      </div>
    </Layout>
  );
};
