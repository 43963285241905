import React from "react";
import { useLayoutEffect, useState } from "react";
import { useAxios } from "../hooks/axios";
import { Layout } from "../components/Layout";
import { CheckInPage } from "./CheckInPage";

interface SetupPageProps {
  // Add any props you need for the SetupPage component
}

export const VerifyCheckInPage: React.FC<SetupPageProps> = () => {
  const [pageLoading, setpageLoading] = useState(true);
  const [isPinWrong, setIsPinWrong] = useState(false);
  const { loading, error, request } = useAxios();

  const verifyCheckInPage = async () => {
    let pin: string = "";
    if (!localStorage.getItem("normal-pin")) {
      pin = window.prompt("Please enter your pin") || "";
      localStorage.setItem("normal-pin", pin);
    }

    try {
      const veriftyPin = localStorage.getItem("normal-pin") || "";
      const response = await request({
        url: "/verifyNormalPage",
        method: "POST",
        data: {
          pin: veriftyPin,
        },
      });
      if (response) {
        setIsPinWrong(false);
      }
    } catch (error) {
      setIsPinWrong(true);
      localStorage.removeItem("normal-pin");
    }

    setpageLoading(false);
  };

  useLayoutEffect(() => {
    verifyCheckInPage();
  }, []);

  // Add your component logic here

  return (
    <Layout title="Check-in">
      <div className="mt-3 register-div w-100 d-flex flex-column justify-content-center align-items-center">
        {pageLoading}
        {isPinWrong && !pageLoading && <div>401: Unauthorized</div>}
        {!isPinWrong && !pageLoading && <CheckInPage />}
      </div>
    </Layout>
  );
};
