import React, { useState, useEffect } from "react";
import { useAxios } from "../hooks/axios";
import useSWR from "swr";
import { API } from "../config";
import {
  Table,
  ScrollArea,
  Text,
  Box,
  createStyles,
  Loader,
  Card,
  Group,
  Button,
  Title,
  Modal,
  TextInput,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";

interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  Card: CardInfo[];
}

interface CardInfo {
  cardAlias: string;
  ActivityLog: ActivityLog[];
}

interface ActivityLog {
  action: string;
  createdAt: string;
  updatedAt: string;
  terminalId: string;
  data: any;
}

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: theme.spacing.md,
  },
  tableContainer: {
    overflowX: "auto",
  },
  responsiveTable: {
    minWidth: "800px",
    [theme.fn.smallerThan("sm")]: {
      minWidth: "600px",
      "& th, & td": {
        padding: "8px 4px",
      },
    },
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "200px",
  },
}));

const fetcher = async (url: string) => {
  const pin = localStorage.getItem("superPin") || "";
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x-pin": pin,
    },
  }).then((res) => res.json());

  console.log(response);

  if (response?.statusCode === 401) {
    localStorage.removeItem("superPin");
    // refresh page
    window.location.reload();
  } else {
    return response;
  }
};

export const UserOverviewPage = () => {
  const { classes } = useStyles();
  const [pinModalOpened, setPinModalOpened] = useState(false);
  const [pin, setPin] = useState<string>(
    localStorage.getItem("superPin") || ""
  );
  const [pinInput, setPinInput] = useState<string>("");
  const [pinError, setPinError] = useState<string>("");

  const { data, error, isLoading, mutate } = useSWR<User[]>(
    pin ? `${API.HOST}/api/overview` : null,
    fetcher
  );

  useEffect(() => {
    if (!pin) {
      setPinModalOpened(true);
    }
  }, [pin]);

  const handlePinSubmit = () => {
    if (pinInput.trim() === "") {
      setPinError("PIN cannot be empty");
      return;
    }
    localStorage.setItem("superPin", pinInput);
    setPin(pinInput);
    setPinModalOpened(false);
    setPinError("");
  };

  const handleLogout = () => {
    localStorage.removeItem("superPin");
    setPin("");
    setPinInput("");
    setPinModalOpened(true);
  };

  if (isLoading) {
    return (
      <Box className={classes.loaderContainer}>
        <Loader />
      </Box>
    );
  }

  if (error) {
    notifications.show({
      title: "Error",
      message: "Failed to fetch data",
      color: "red",
    });
    return null;
  }

  return (
    <Box className={classes.container}>
      <Group position="apart" mb="md">
        <Title order={3}>User Overview</Title>
        <Button variant="outline" size="xs" onClick={handleLogout}>
          Logout
        </Button>
      </Group>

      <Text size="sm" mb="md">
        User Count: {data?.length} <br />
        User Checked in Count:
        {data?.filter((user) => user.Card.length > 0)?.length}
      </Text>

      <ScrollArea className={classes.tableContainer}>
        <Table
          className={classes.responsiveTable}
          highlightOnHover
          striped
          withColumnBorders
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Linked Card Alias</th>
              <th>Locations Visited</th>
              <th>Check-In At</th>
              <th>Activity</th>
            </tr>
          </thead>
          <tbody>
            {data
              ?.filter((user) => user.Card.length > 0)
              .map((user, ix) => {
                const userCards = user.Card.map((card) => card.cardAlias);
                const userCardsActivity = user.Card.map(
                  (card) => card.ActivityLog
                );
                const userAllActivity = userCardsActivity.flat();

                const userCheckIn = userAllActivity
                  .filter(
                    (activity) => activity.action === "CHECK_IN_CARD_PAYMENT"
                  )
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt).getTime() -
                      new Date(b.createdAt).getTime()
                  );

                const userStages = userAllActivity
                  .filter(
                    (activity) =>
                      activity.action === "CHECK_POINT_CARD_ACQUISITION"
                  )
                  .sort(
                    (a, b) =>
                      new Date(a.createdAt).getTime() -
                      new Date(b.createdAt).getTime()
                  )
                  .reduce((acc: ActivityLog[], current) => {
                    const existing = acc.find(
                      (item) => item.terminalId === current.terminalId
                    );
                    if (existing) {
                      if (!Array.isArray(existing.data)) {
                        existing.data = [];
                      }
                      existing.data = [
                        ...existing.data,
                        ...(Array.isArray(current.data) ? current.data : []),
                      ];
                      existing.updatedAt = current.updatedAt;
                    } else {
                      current.data = Array.isArray(current.data)
                        ? current.data
                        : [];
                      acc.push(current);
                    }
                    return acc;
                  }, []);

                return (
                  <tr key={user.id}>
                    <td>{ix + 1}</td>
                    <td>{user.email}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>
                      {userCards.map((card, index) => (
                        <React.Fragment key={index}>
                          {card}
                          {index < userCards.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                    </td>
                    <td>{userStages.length}</td>
                    <td>
                      {userCheckIn[0]?.createdAt
                        ? new Date(userCheckIn[0].createdAt).toLocaleTimeString(
                            "nl-NL",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        : "N/A"}
                    </td>
                    <td>
                      <Button
                        component="a"
                        href={`/activity?id=${user.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        size="xs"
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </ScrollArea>

      <Modal
        opened={pinModalOpened}
        onClose={() => {}}
        title="Enter Admin PIN"
        withCloseButton={false}
        centered
      >
        <TextInput
          label="PIN"
          placeholder="Enter PIN"
          type="password"
          value={pinInput}
          onChange={(e) => setPinInput(e.currentTarget.value)}
          error={pinError}
        />
        <Group position="right" mt="md">
          <Button onClick={handlePinSubmit}>Submit</Button>
        </Group>
      </Modal>
    </Box>
  );
};
